<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="title">{{ info.name }}</div>
            <div class="sub-title">
                <span>{{ typeName }}{{ info.settingName }}</span>

                <span>行业领域：{{ info.fieldName }}</span>
                <span>{{ ownerName }}{{ info.owner }}</span>
            </div>
            <div class="content" v-html="info.manual"></div>
        </div>
    </bread-pages>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import BreadPages from '../../components/page/BreadPages.vue';
import { digitalTypeOptions } from '../../utils/variables';
export default {
    components: { BreadPages },
    data() {
        return {
            loading: false,
            info: {},
            digitalTypeOptions
        };
    },
    computed: {
        typeName() {
            if (this.info.type === 'COMPANY') {
                return '企业性质：';
            } else {
                return this.getLabelName(this.info.type, digitalTypeOptions) + '区分：';
            }
        },
        ownerName() {
            if (this.info.type === 'COMPANY') {
                return '所属区域：';
            } else {
                return this.getLabelName(this.info.type, digitalTypeOptions) + '所属人：';
            }
        }
    },
    mounted() {
        this.loading = true;
        this.$http.get('/digital/get/' + this.$route.query.id).then(res => {
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);

            let info = digitalTypeOptions.find(item => {
                return item.value === res.type;
            });
            this.$EventBus.$emit('changePreTitle', {
                name: info.label,
                path: '/digital',
                query: {
                    type: res.type
                }
            });
            this.$EventBus.$emit('changeTitle', info.label + '详情');
            document.title = info.label + '详情';
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 0 150px 60px;
    box-sizing: border-box;
    .min-height(606px);
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 34px;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid @bg;
}
.appContainer {
    background: #ffffff !important;
}
.sub-title {
    .flex();
    justify-content: center;
    font-size: 14px;
    color: #939599;
    line-height: 24px;
    span + span {
        margin-left: 40px;
    }
    padding: 20px 0 40px;
}

.content {
    line-height: 28px;
    p {
        font-size: 14px;
        color: #000000;
        line-height: 28px;
    }
}
</style>
